table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.footer {
  margin-top: 50px;
  position: relative;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 400px; /* Ajusta el ancho según tu necesidad */

}

.center {
    position: relative;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px; /* Ajusta el ancho según tu necesidad */
    width: 100px;
    height: 100px;
}
  
  /* Aplicar estilos al h1 */
h1 {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center; /* Centrar el texto del h1 */
}

h2 {
    margin-top: 20px;
    text-align: center; /* Centrar el texto del h1 */
}