@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto Condensed', sans-serif;
}

:root {
    --blackAirsoftLegends: #0c0c0c;
    --marginColor: #29335c;
    --marginColorLiight: #5767aa;
    --secondaryColor: #FD9800;
    --textColor: #f9f6f6;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2rem;
    background-color: var(--blackAirsoftLegends);
    color: var(--textColor);
    width: 100%; /* Añadir "width: 100%" */
}


nav a {
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;
}

nav a:hover {
    color: var(--secondaryColor);
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
}

@media only screen and (max-width: 1024px) {
    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: sticky;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5 rem;
        background-color: var(--blackAirsoftLegends);
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 10;
        flex-basis: 100%; /* Cambiar a "flex-basis: 100%" */
    }

    header .responsive_nav{
        transform: none;
    }
}

header nav {
  margin: 0 auto; /* Añadir "margin: 0 auto" */
}

